import ApiServices from "./ApiServices";

const Get = async (params) => {
    const instance = await ApiServices();
    return instance
        .get(
            `/page/category/getData?limit=${params.limit}&page=${params.page}`
        )
        .then((res) => res.data);
};

const getDataChild = async (params) => {
    const instance = await ApiServices();
    return instance
        .get(
            `/page/category/getDataChild?limit=${params.limit}&page=${params.page}&category_id=${params.category_id}`
        )
        .then((res) => res.data);
};

const Create = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/category/submitAdd`,params
        )
        .then((res) => res.data);
};

const Put = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/category/submitEdit/${params.category_id || params.id_child_category}`,params
        )
        .then((res) => res.data);
};

const Delete = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/category/submitDelete/${params.category_id}`,params
        )
        .then((res) => res.data);
};

export default {
    Get,
    Create,
    Put,
    Delete,
    getDataChild
};
