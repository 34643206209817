import React from 'react';
import { Breadcrumb, Skeleton } from 'antd';
import { Row, Col, List, Avatar, Card } from 'antd';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link, useNavigate } from "react-router-dom";
import TicketServices from "../../services/TicketServices";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
function Home() {
    const usr = JSON.parse(localStorage.getItem('usr'));
    const [notif, setNotif] = React.useState([]);
    const [pic, setPic] = React.useState([]);
    const [user, setUser] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [expand, setExpand] = React.useState(false);
    const [status, setStatus] = React.useState(null);
    const [bestPic, setBestPic] = React.useState(null);
    const [sumOf, setSumOf] = React.useState(null);
    let navigate = useNavigate();

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            x: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return '';
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            }
        },
    };
    const handleClickRoute = (menu) => {
        navigate(menu);
    }
    const handleExpand = (menu) => {
        setExpand(!expand);
    }

    React.useEffect(() => {
        function fetch() {
            TicketServices.GetPic().then((res) => {
                setPic(res.data);
            });
            TicketServices.GetUser().then((res) => {
                setUser(res.data);
            });
            TicketServices.GetBestStatus().then((res) => {
                const labels = [];
                const datas = [];
                res.data.forEach(row => {
                    labels.push(row.category_name);
                    datas.push(row.total);
                })
                const data = {
                    labels,
                    datasets: [
                        {
                            label: 'Total Status Pekerjaan',
                            data: datas,
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                    ],
                };
                setStatus(data);
            });
            TicketServices.getSumOfTotal().then((res) => {
                const labels = [];
                const datas = [];
                res.data.forEach(row => {
                    labels.push(row.category_name);
                    datas.push(row.total);
                })
                const data = {
                    labels,
                    datasets: [
                        {
                            label: 'Sum of by Status Pekerjaan',
                            data: datas,
                            backgroundColor: 'blue',
                        },
                    ],
                };
                setSumOf(data);
            });
            TicketServices.getBestPicByValue().then((res) => {
                const labels = [];
                const datas = [];
                res.data.forEach(row => {
                    labels.push(row.pic);
                    datas.push(row.grand_total);
                })
                const data = {
                    labels,
                    datasets: [
                        {
                            label: 'Sum of by PIC User',
                            data: datas,
                            backgroundColor: 'green',
                        },
                    ],
                };
                setBestPic(data);
            });
            setLoading(false);
        }
        fetch();
    }, [TicketServices]);

    React.useEffect(() => {
        TicketServices.GetNotif({ limit: expand ? 200 : 5 }).then((res) => {
            setNotif(res.data);
        });
    }, [expand])
    return (
        <div className="container mx-auto flex flex-wrap flex-row">
            <div className='w-full px-3'>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="relative flex w-full px-3 my-3 gap-2">
                {<div className="w-6/12 p-3  bg-white">
                    <label className='text-[15px] mb-2'>5 PIC Pekerjaan Terbanyak </label>
                    <table className='w-full border-[1px] text-center pt-2'>
                        <tr>
                            <th>Nama</th>
                            <th>Jumlah</th>
                        </tr>
                        {pic.map((row, key) => (<tr key={key}>
                            <td >{row.pic}</td>
                            <td>{row.total}</td>
                        </tr>))}
                    </table>
                </div>}
                {<div className="w-6/12 p-3  bg-white">
                    <label className='text-[15px] mb-2'>User Pekerjaan Terbanyak </label>
                    <table className='w-full border-[1px] text-center pt-2'>
                        <tr>
                            <th>Nama</th>
                            <th>Jumlah</th>
                        </tr>
                        {user.map((row, key) => (<tr key={key}>
                            <td >{row.nama_pic}</td>
                            <td>{row.total}</td>
                        </tr>))}
                    </table>
                </div>}
                <div className={`w-6/12 p-3 bg-white`}>
                    <List
                        itemLayout="horizontal"
                        dataSource={notif}
                        style={{ height: 200, overflowY: 'scroll' }}
                        renderItem={item => (
                            <List.Item style={{ background: item.is_read === null ? '#ffb1c1' : '#ffff' }}>
                                <List.Item.Meta
                                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                    title={<Link to={`/home/detail-ticket/${item.id_ticket}`}>Hallo,{usr.name}</Link>}
                                    description={`${item.nama_ticket} telah di disposisikan kepada anda`}
                                />
                            </List.Item>
                        )}></List>
                    {notif.length >= 5 && <a href="#" onClick={() => handleExpand()}>Lihat {expand ? 'Sebagian' : 'Semua'}</a>}
                </div>
            </div>
            <Row gutter={4}>
                {<Col span={24}>
                    <div className="w-full p-3 bg-white shadow-xl sm:rounded-lg my-2">
                        {!loading ? <><label className='text-[20px]'>Total Status Pekerjaan</label>
                            {status?.datasets?.length > 0 && <Bar options={options} data={status} />}
                            <p>Note: Arahkan ke bar chart untuk melihat detail</p></>
                            : <Skeleton active />}
                    </div>
                </Col>}
                {<Col span={24}>
                    <div className="w-full p-3 bg-white shadow-xl sm:rounded-lg my-2">
                        {!loading ? <><label className='text-[20px]'>Sum of by Status Pekerjaan</label>
                            {sumOf?.datasets?.length > 0 && <Bar options={options} data={sumOf} />}
                            <p>Note: Arahkan ke bar chart untuk melihat detail</p></>
                            : <Skeleton active />}
                    </div>
                </Col>}
                {<Col span={24}>
                    <div className="w-full p-3 bg-white shadow-xl sm:rounded-lg my-2">
                        {!loading ? <><label className='text-[20px]'>Sum of by Pic User</label>
                            {bestPic?.datasets?.length > 0 && <Bar options={options} data={bestPic} />}
                            <p>Note: Arahkan ke bar chart untuk melihat detail</p></>
                            : <Skeleton active />}
                    </div>
                </Col>}
            </Row>
        </div >)
}

export default Home