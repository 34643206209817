import React from 'react';
import { Outlet } from "react-router-dom";
import Headers from "./header";
import Siders from './sider';
import { Layout, Breadcrumb } from 'antd';

const { Content, Sider } = Layout;
function Layouts(props) {
  const isMobile = window.innerWidth < 700;
  const [state, setState] = React.useState({
    collapsed: isMobile ? true : false,
  })
  const toggle = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };
  return (<Layout>
    <Headers handleToggle={toggle} state={state} />
    <Layout style={{minHeight:520}}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={280}
        trigger={null}
        collapsible
        collapsed={state.collapsed} className={isMobile ? 'fixed z-40' : state.collapsed ? "fixed z-40" : ""}>
        <Siders handleToggle={toggle}/>
      </Sider>
      <Layout className={isMobile ? 'ml-[0px]' : state.collapsed ? "ml-[0px]" : "ml-[0px]"} style={{ padding: '0 24px 24px' }}>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  </Layout>);
}

export default Layouts;
