import ApiServices from "./ApiServices";

const usrlgn = JSON.parse(localStorage.getItem("usrlgn"));
const PostLogin = async (params) => {
    const instance = await ApiServices(true);
    return instance.post(`/login`, params).then(
        (result) => result.data
    )
}

const PostForgotPassword = async (params) => {
    const instance = await ApiServices(true);
    return instance.post(`/app/forgotPassword`, params).then(
        (result) => result.data
    )
}

const PostLogout = async () => {
    const params = {
        id: usrlgn?.id || 0
    }

    const instance = await ApiServices(true);
    return instance.post(`/logout`, params).then(
        (result) => result.data
    )
}

const getUserInfo = async () => {
    const instance = await ApiServices();
    return instance.get(`/app/getUserInfo`)
}
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { PostLogin, PostForgotPassword, PostLogout, getUserInfo }
