import ApiServices from "./ApiServices";

const Get = async (filter) => {
    const instance = await ApiServices();
    let filterParams = "";
    if (filter) {
        let arrayFilter = Object.keys(filter);
        let count = 0;
        for (let i = 0; i < arrayFilter.length; i++) {
            if (filter[arrayFilter[i]]) {
                if (count === 0) {
                    filterParams += `?${arrayFilter[i]}=${filter[arrayFilter[i]]}`;
                } else {
                    filterParams += `&${arrayFilter[i]}=${filter[arrayFilter[i]]}`;
                }
            }
            count++;
        }
    }

    return instance
        .get(
            `/page/ticket/getData${filterParams}`
        )
        .then((res) => res.data);
};

const GetById = async (params) => {
    const instance = await ApiServices();
    return instance
        .get(
            `/page/ticket/getDataById?id_ticket=${params.id_ticket}`
        )
        .then((res) => res.data);
};

const GetSpesificData = async (params, ticket) => {
    const instance = await ApiServices();
    return instance
        .get(
            `/page/ticket/getDataByIdUser?id_user=${params}&id_ticket=${ticket}`
        )
        .then((res) => res.data);
};

const Create = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/submitAdd`, params
        )
        .then((res) => res.data);
};

const UpdateStatus = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/updateStatus`, params
        )
        .then((res) => res.data);
};

const SumbitPO = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/submitPO`, params
        )
        .then((res) => res.data);
};


const Put = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/submitEdit`, params
        )
        .then((res) => res.data);
};

const Patch = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/submitPatch`, params
        )
        .then((res) => res.data);
};

const Delete = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/submitDelete`, params
        )
        .then((res) => res.data);
};
const mailSender = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/mail/sendMail`, params
        )
        .then((res) => res.data);
};

const Setujui = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/submitApprove`, params
        )
        .then((res) => res.data);
};

const GetNotif = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/notification?limit=${params.limit}`, params
        )
        .then((res) => res.data);
};

const PostNotif = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/updateNotif`, params
        )
        .then((res) => res.data);
};

const GetPic = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/getBestPic`, params
        )
        .then((res) => res.data);
};

const GetUser = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/home/index`, params
        )
        .then((res) => res.data);
};

const GetBestStatus = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/getBestStatus`, params
        )
        .then((res) => res.data);
};
const getSumOfTotal = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/getSumOfTotal`, params
        )
        .then((res) => res.data);
};
const getBestPicByValue = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/ticket/getBestPicByValue`, params
        )
        .then((res) => res.data);
};
const BulkCreate = async (params) => {
    const instance = await ApiServices();
    return instance
        .post(
            `/page/bulk/create`, params
        )
        .then((res) => res.data);
};

export default {
    Get,
    BulkCreate,
    GetById,
    GetSpesificData,
    Create,
    SumbitPO,
    UpdateStatus,
    Put,
    Patch,
    Delete,
    mailSender,
    Setujui,
    GetNotif,
    PostNotif,
    GetPic,
    GetBestStatus,
    getSumOfTotal,
    getBestPicByValue,
    GetUser
};
