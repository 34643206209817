import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { message } from 'antd';
import logo from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import AuthServices from "../../services/AuthServices";
import TicketServices from "../../services/TicketServices";

function Login() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: "all",
        reValidateMode: "onChange",
    }
    );

    const mutationLogin = useMutation(AuthServices.PostLogin, {
        onError: (error, variables, context) => {
            console.log(error);
        },
        onSuccess: (data, variables, context) => {
            reset({
                username: "",
                password: "",
            });
            if (data.success_message) {
                window.sessionStorage.setItem("token", data.jwt);
                localStorage.setItem('usr', JSON.stringify(data.user));
                navigate('/home');
                window.location.reload();
            }

            setErrorMessage(data.error_message);
        },
    });

    const onSubmitLogin = data => {
        const params = {
            ...data,
        };
        if (forgotPassword) {
            AuthServices.PostForgotPassword(params).then((res) => {
                const response = res;
                    const parameter = {
                        sender: params.username,
                        template: `
                        <div><img src="https://osmoproc.ptdak.com/api/image/get/logo.png" width="150" alt="logo-dak" /></div>
                        <h1> Password anda telah di reset otomatis, password anda sekarang 'passwordNew'. </h1>`
                    }
                    TicketServices.mailSender(parameter).then((res) => {
                        setForgotPassword(false);
                        reset({
                            username: "",
                            password: "",
                        });
                        message.info('Password telah di rubah, dan dikirimkan ke email.');
                    });
            });
        } else {
            mutationLogin.mutate(params);
        }

    };
    return (
        <div>
            <div className="min-h-screen py-6 bg-login-background bg-no-repeat bg-cover bg-center flex flex-col justify-center relative overflow-hidden sm:py-12">
                <img src={logo} alt="logo" className='w-[184px] h-[74px] fixed top-0 left-3' />
                <div className={`absolute md:right-[200px] z-40 md:w-[450px]`}>
                    <div className="relative px-6 pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:mx-3 sm:mx-auto sm:rounded-lg sm:px-10">
                        <div className="max-w-md mx-auto">
                            <div className="divide-y divide-gray-300/50">
                                <h1 className="text-[#274C77] italic font-black text-[36px]"> Hello Again! </h1>
                                <span className="text-[#274C77] font-bold text-[18px]">Selamat Datang</span>
                                <div className="py-8 text-base leading-7 space-y-6 text-gray-600">
                                    {errorMessage && <div className="bg-[#e63629] text-white p-3 w-full my-2 rounded-sm">Username dan Password Salah!!</div>}
                                    <form onSubmit={handleSubmit(onSubmitLogin)} className="page_login___form">
                                        <input className={`border-2 rounded-xl ${errors.username ? 'border-rose-600' : 'my-2'} p-3 w-full`} type={forgotPassword ? "mail" : "text"} placeholder={forgotPassword ? "Email" : "Username"} {...register("username", { required: true })} />
                                        <span className="text-[#e63629] w-full mb-2 text-[12px]">{errors.username && (forgotPassword ? "Email is required" : "Username is required")}</span>
                                        {!forgotPassword && <><input className={`border-2 rounded-xl ${errors.password ? 'border-rose-600' : 'my-2'} p-3 w-full`} type="password" placeholder="Password" {...register("password", { required: true })} />
                                            <p className="text-[#e63629] mb-2 w-full text-[12px]">{errors.password && "Password is required"}</p>
                                            <label className="my-2">
                                                <input type="checkbox" className='mr-3' />
                                                <span>Remember Me</span>
                                            </label>
                                            <a href="#top" alt="..." className='absolute right-10' onClick={() => setForgotPassword(true)}> Lupa Password?</a></>}
                                        {forgotPassword && <div onClick={() => setForgotPassword(false)}> Kembali</div>}
                                        <button className='bg-[#274C77] my-2 text-white p-3 w-full rounded-xl' type="submit" >{forgotPassword ? 'Submit' : 'Login'}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fixed top-0 hidden md:inline-flex
        right-0 w-[500px] min-h-screen bg-[#F8F8FB] mix-blend-normal shadow-[-19_20.2109px 40.4219_19px rgba(39, 76, 119, 0.16)]
    rounded-tl-[163px]"></div>
            </div>
        </div>)
}

export default Login