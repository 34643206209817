import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({isAuthenticated,children }) => {
  return isAuthenticated ?
    <React.Suspense fallback={<>...</>}>
      {children}
    </React.Suspense> :
    <Navigate to="/login" />;
};

export default PrivateRoute;
