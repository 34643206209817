import React from 'react';
import { Link } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import { classNames } from "css-hash";
import logo from "../../assets/img/logo.png";
import user from "../../assets/img/user.png";
import Popper from "../popper";
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

export default function Header(props) {
    const { state, handleToggle } = props;
    const usr = JSON.parse(localStorage.getItem('usr'));
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        document.addEventListener("scroll", handleScroll);
        return () => document.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setMobileMenuIsOpen(false);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const menus = [
    ];

    const handleClickMenu = () => {
        setMobileMenuIsOpen(!mobileMenuIsOpen);
    }

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.clear();
    }

    const MENU_RENDER = [];
    menus.forEach((item, index) => {
        if (!item.children) {
            MENU_RENDER.push(
                <li
                    className="py-3 lg:py-0 text-center cursor-pointer"
                    key={index}
                >
                    {/* <Link href={item.path}> */}
                    <Link onClick={handleClickMenu} to={item.path} className="text-black text-[#274C77] no-underline hover:text-white hover:bg-gray-700 rounded hover:text-underline py-2 px-4">
                        {item.title}
                    </Link>
                    {/* </Link> */}
                </li>,
            );
        } else {
            MENU_RENDER.push(
                <Popper
                    key={index}
                    options={{
                        placement: "bottom-start",
                    }}
                    hovered
                >
                    {(referenceRef, popperRef, { attributes, open }) => {
                        return (
                            <Fragment>
                                <li
                                    ref={referenceRef}
                                    className={`text-white no-underline hover:bg-gray-700 ${open ? "bg-gray-700" : ""
                                        } rounded hover:text-underline py-2 px-4 cursor-pointer`}
                                >
                                    {item.title}
                                </li>
                                {open && (
                                    <div ref={popperRef} {...attributes.popper} className="py-1">
                                        <div className="w-56 rounded-md bg-white shadow-xl border-2 border-solid border-gray-700">
                                            <div className="py-1 cursor-pointer">
                                                {item.children.map((item2, index2) => (
                                                    <Link key={index2} href={item2.path}>
                                                        <div className="block px-4 py-2 capitalize text-black hover:bg-gray-700 hover:text-white">
                                                            {item2.title}
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        );
                    }}
                </Popper>,
            );
        }
    });

    return (
        <nav
            className={classNames(
                "w-full z-30 top-0 bg-[#F8F8FB]",
                scrollPosition > 10 && "shadow-[0_20.2109px 40.4219_-6.73698px rgba(39, 76, 119, 0.16)] bg-[#F8F8FB]",
                mobileMenuIsOpen && "lg:shadow-none shadow-lg bg-[#F8F8FB]",
            )}
        >
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
                <div className="pl-4 flex items-center">
                    <div className="flex pr-6">
                        <img
                            className="flex-1 w-[140px] h-[55px]"
                            src={logo}
                            alt="logo pt-dak"
                        />
                    </div>
                    {React.createElement(state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger text-[24px] pt-[12px]',
                        onClick: handleToggle,
                    })}
                    <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 text-black p-4 lg:p-0 z-20">
                        <ul className="mt-2 list-reset lg:flex justify-end flex-1 items-center">
                            {MENU_RENDER}
                        </ul>
                    </div>
                </div>

                <div>
                    <div className="mt-2 flex items-center">
                        {menus.length > 0 && (
                            <div className="block lg:hidden pr-4">
                                <button
                                    onClick={() => {
                                        setMobileMenuIsOpen(!mobileMenuIsOpen);
                                    }}
                                    className="inline-flex items-center justify-center p-2 rounded-md text-[#274C77]  border-2 border-solid border-[#274C77] hover:bg-gray-700 focus:outline-none"
                                >
                                    {!mobileMenuIsOpen && (
                                        <svg
                                            className="block h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M4 6h16M4 12h16M4 18h16"
                                            ></path>
                                        </svg>
                                    )}
                                    {mobileMenuIsOpen && (
                                        <svg
                                            className="block h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            ></path>
                                        </svg>
                                    )}
                                </button>
                            </div>
                        )}
                        <a
                            href="#img-user"
                            className="w-full pr-6"
                        >
                            <img src={user} alt="user" className="my-1 float-left bg-red rounded-full w-[30px] h-[30px] mx-2" />
                            {usr && <span className="text-[#274C77] hidden md:inline-flex float-right py-2">{usr.name}</span>}
                        </a>
                        <Link onClick={handleLogout} to="/login"
                            className="pr-3"
                        >
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.0062 10.5268L20.6638 11.0529C20.9098 10.7454 20.9098 10.3083 20.6638 10.0008L20.0062 10.5268ZM12.1113 9.68473C11.6462 9.68473 11.2691 10.0618 11.2691 10.5268C11.2691 10.9919 11.6462 11.369 12.1113 11.369L12.1113 9.68473ZM17.1549 15.439L20.6638 11.0529L19.3486 10.0008L15.8397 14.3868L17.1549 15.439ZM20.6638 10.0008L17.1549 5.61472L15.8397 6.66686L19.3486 11.0529L20.6638 10.0008ZM20.0062 9.68473L12.1113 9.68473L12.1113 11.369L20.0062 11.369L20.0062 9.68473Z" fill="#274C77" />
                                <path d="M12.9884 13.92L12.9884 14.156C12.9884 15.7744 12.9884 16.5835 12.5144 17.143C12.0405 17.7025 11.2423 17.8355 9.646 18.1016L9.44826 18.1346C6.20517 18.6751 4.58362 18.9453 3.52275 18.0466C2.46187 17.1479 2.46187 15.504 2.46187 12.2162L2.46187 8.8375C2.46187 5.54967 2.46187 3.90575 3.52275 3.00706C4.58362 2.10836 6.20517 2.37862 9.44826 2.91913L9.646 2.95209C11.2423 3.21814 12.0405 3.35117 12.5144 3.91066C12.9884 4.47015 12.9884 5.27932 12.9884 6.89767L12.9884 6.96013" stroke="#274C77" strokeWidth="1.68424" strokeLinecap="round" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>

            <div
                className={`lg:hidden ${mobileMenuIsOpen ? "block" : "hidden"
                    } w-full container mx-auto flex flex-wrap items-center mt-0 py-3`}
            >
                <ul className="list-reset lg:flex justify-end flex-1 items-center">
                    {MENU_RENDER}
                </ul>
            </div>
            <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
        </nav>
    );
}
