import Axios from "axios";
import { toast } from "react-toastify";

const token = window.sessionStorage.getItem("token") || "";
const baseURL = process.env.REACT_APP_API_URL;

const defaultHeader = async () => {
  let requestHeader = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return requestHeader;
};

const authHeader = async (auth_token) => {
  let requestHeader = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (auth_token || token) {
    requestHeader.Authorization = `Bearer ${auth_token || token}`
  }
  return requestHeader;
};

const ApiServices = async (noValidate, token) => {
  let header = await defaultHeader();

  if (!noValidate) {
    header = await authHeader(token);
  }

  const instance = Axios.create({
    baseURL: baseURL,
    timeout: 60000,
    headers: header,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const response = error.response;

      if (response.status === 401 && !token) {
        toast.error("Session Expired", {
          toastId: "beewellSessionExpired",
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            localStorage.removeItem("token");
            window.location.href = "/login";
          },
        });
      } else {
        return Promise.reject(error);
      }
    }
  );

  return instance;
};

export default ApiServices;
